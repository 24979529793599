import React, { useState } from "react"
import { navigate } from "gatsby"
import { useMutation } from "@apollo/client"
import { SurveyForm } from "../crud/createRegister"

// Components
import { SectionTitle, Input } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { toast } from "react-toastify"
import { Seo } from "../components/seo"
const Survey = () => {
  const [register, setRegister] = useState({
    FullName: "",
    Email: "",
    PhoneNumber: "",
    State: "学生（就職活動中）",
    OtherState: "",
    Country: "",
    ReasonsToWorkOnMexico: "",
    History: "",
    Period: "",
    CountryName: "",
    Purpose: "",
    SpanishLevel: "ネイティブレベル",
    SpanishQualifications: "",
    OtherLanguage: "",
    messageError: {},
  })
  const [loading, setLoading] = useState(false)
  const { messageError } = register
  const emailRegex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  const handleChange = e => {
    const { name, value } = e.target
    setRegister(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = register.messageError
    switch (name) {
      case "FullName":
        errors.FullName =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break

      case "Email":
        errors.Email =
          value === ""
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : "" || emailRegex.test(value)
            ? ""
            : "これは有効なメールではありません"
        break

      case "Country":
        errors.Country =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break

      case "ReasonsToWorkOnMexico":
        errors.ReasonsToWorkOnMexico =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      default:
        break
    }
  }
  const [addSurvey] = useMutation(SurveyForm, {
    onCompleted: () => {
      navigate("/thankyou")
    },
    onError: () => {
      setLoading(false)
      toast.error("サーバーエラー")
    },
  })

  const handleSurvey = () => {
    addSurvey({
      variables: {
        input: {
          data: {
            FullName: register.FullName,
            Email: register.Email,
            PhoneNumber: register.PhoneNumber,
            State: register.State + ": " + register.OtherState,
            Country: register.Country,
            ReasonsToWorkOnMexico: register.ReasonsToWorkOnMexico,
            History: register.History,
            Period: register.Period,
            CountryName: register.CountryName,
            Purpose: register.Purpose,
            SpanishLevel: register.SpanishLevel,
            SpanishQualifications: register.SpanishQualifications,
            OtherLanguage: register.OtherLanguage,
          },
        },
      },
    })
  }

  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }

  const handleSubmit = event => {
    event.preventDefault()
    // if (register.isVerified) {
    if (
      register.FullName !== "" &&
      register.Email !== "" &&
      register.ReasonsToWorkOnMexico !== "" &&
      register.Country !== ""
    ) {
      if (formValidate(register.messageError, register)) {
        setLoading(true)
        handleSurvey()
      } else {
        toast.error("フォームが正しくありません")
      }
    } else {
      toast.error("入力内容を再度ご確認ください。")
    }
  }
  return (
    <>
      <Seo
        title={`面談事前アンケート | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
      />
      <section className="container contact-us">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="contact-us__responsible-for-company">
              <SectionTitle>面談事前アンケート</SectionTitle>
              <hr className="u-line-bottom" />
              <p>
                面談へ進む前に下記のアンケートにお答えください。<br></br>
                できるだけ詳細にご記載いただくことで、面談の際にスムーズにお話を進めることができます。何卒、ご協力をお願いいたします。
              </p>
              <form
                className="contact-us__form-contact"
                onSubmit={handleSubmit}
              >
                <div className="form-responsive">
                  <Input>
                    <label htmlFor="氏名">
                      <p className="form-label">氏名 </p>
                      <span>必須</span>
                    </label>
                    <input
                      type="text"
                      id="氏名"
                      name="FullName"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                    {messageError.FullName && (
                      <span className="field-validation">
                        {messageError.FullName}
                      </span>
                    )}
                  </Input>

                  <Input>
                    <label htmlFor="メールアドレス">
                      <p className="form-label">メールアドレス </p>
                      <span>必須</span>
                    </label>
                    <input
                      type="text"
                      id="メールアドレス"
                      name="Email"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                    {messageError.Email && (
                      <span className="field-validation">
                        {messageError.Email}
                      </span>
                    )}
                  </Input>
                  <Input>
                    <label htmlFor="電話番号">
                      <p className="form-label">電話番号</p>
                    </label>
                    <input
                      type="text"
                      id="電話番号"
                      name="PhoneNumber"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                  </Input>
                  <Input>
                    <label htmlFor="現在の状況">
                      <p className="form-label">現在の状況</p>
                      <span>必須</span>
                    </label>
                    <select
                      id="現在の状況"
                      name="State"
                      onChange={handleChange}
                      onBlur={handleChange}
                    >
                      <option value="学生（就職活動中）">
                        学生（就職活動中）
                      </option>
                      <option value="就業中（転職活動中）">
                        就業中（転職活動中）
                      </option>
                      <option value="休職中（現在は無職）">
                        休職中（現在は無職）
                      </option>
                      <option value="その他">その他</option>
                    </select>
                    {register.State === "その他" && (
                      <div className="survey__other-state">
                        <input
                          type="text"
                          id="現在の状況"
                          name="OtherState"
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </div>
                    )}
                  </Input>
                  <Input>
                    <label htmlFor="在住国">
                      <p className="form-label">在住国 </p>
                      <span>必須</span>
                    </label>
                    <input
                      type="text"
                      id="在住国"
                      name="Country"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                    {messageError.Country && (
                      <span className="field-validation">
                        {messageError.Country}
                      </span>
                    )}
                  </Input>

                  <Input className="survey__vcenter">
                    <label htmlFor="メキシコでの就業（転職）に興味を持った理由">
                      <p className="form-label">
                        メキシコでの就業 （転職）<br></br>に興味を持った理由{" "}
                      </p>
                      <span>必須</span>
                    </label>
                    <input
                      type="text"
                      id="メキシコでの就業（転職）に興味を持った理由"
                      name="ReasonsToWorkOnMexico"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                    {messageError.ReasonsToWorkOnMexico && (
                      <span className="field-validation">
                        {messageError.ReasonsToWorkOnMexico}
                      </span>
                    )}
                  </Input>
                  <Input className="survey__vcenter">
                    <label htmlFor="これまでのご経歴">
                      <p className="form-label">
                        これまでのご経歴<br></br>
                        <br></br>
                        （例）12年4月～15年3月◯株式会社　▲職<br></br>
                        　　　 15年4月〜 ◇株式会社　◎職
                      </p>
                    </label>
                    <textarea
                      type="text"
                      id="これまでのご経歴"
                      name="History"
                      onChange={handleChange}
                    />
                  </Input>
                  <Input className="survey">
                    <p className="survey__p">
                      スペイン語圏への留学 or 居住経験
                    </p>
                    <label htmlFor="期間">
                      <p className="form-label">期間 :</p>
                    </label>
                    <input
                      type="text"
                      id="期間"
                      name="Period"
                      onChange={handleChange}
                    />
                    <label htmlFor="国名">
                      <p className="form-label">国名 :</p>
                    </label>
                    <input
                      type="text"
                      id="国名"
                      name="CountryName"
                      onChange={handleChange}
                    />
                    <label htmlFor="目的">
                      <p className="form-label">目的 :</p>
                    </label>
                    <textarea
                      type="text"
                      id="目的"
                      name="Purpose"
                      onChange={handleChange}
                    />
                  </Input>

                  <Input>
                    <label htmlFor="スペイン語スキルについて">
                      <p className="form-label">スペイン語スキルについて</p>{" "}
                      <span>必須</span>
                    </label>
                    <select
                      id="スペイン語スキルについて"
                      name="SpanishLevel"
                      onChange={handleChange}
                      onBlur={handleChange}
                    >
                      <option value="ネイティブレベル">ネイティブレベル</option>
                      <option value="ビジネスレベル">ビジネスレベル</option>
                      <option value="日常会話レベル">日常会話レベル</option>
                      <option value="初級レベル">初級レベル</option>
                      <option value="なし">なし</option>
                    </select>
                  </Input>
                  <Input>
                    <label htmlFor="スペイン語の資格">
                      <p className="form-label">スペイン語の資格</p>
                    </label>
                    <input
                      type="text"
                      id="スペイン語の資格"
                      name="SpanishQualifications"
                      onChange={handleChange}
                    />
                  </Input>
                  <Input>
                    <label htmlFor="その他言語スキル">
                      <p className="form-label">その他言語スキル</p>
                    </label>
                    <input
                      type="text"
                      id="その他言語スキル"
                      name="OtherLanguage"
                      onChange={handleChange}
                    />
                  </Input>

                  <div className="u-text-center">
                    {loading === false ? (
                      <input
                        type="submit"
                        value="申し込む"
                        className="btn-submit"
                      />
                    ) : (
                      <p className="buttonload">
                        <i className="fa fa-circle-o-notch fa-spin"></i>
                        &nbsp;&nbsp;少々お待ちください
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <Contact />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default Survey
